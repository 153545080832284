@font-face {
  font-family: 'Segma';
  font-weight: bold;
  src: local('Segma'), url('./fonts/SegmaBold.otf'); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'Segma';
  font-weight: lighter;
  src: local('Segma-Light'), url('./fonts/SegmaLight.otf'); /* IE9 Compat Modes */
}

html,
body {
  background-color: #efefef;
  font-family: 'Segma';
  margin: 0;
  padding: 0;
  cursor: none;
}

body {
  overflow: hidden;
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

body * {
  user-select: none;
}

body *.email, body *.email * {
  user-select: text;
  color: inherit;
  text-decoration: none;
  cursor: none;
}

@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5)
  and (orientation: portrait) {
    body {
      width: 100vw;
      height: 100vh;
      overflow: visible;
    }
    #root {
      width: calc(100vw / 1.3);
      height: calc(100vh / 1.3);
      overflow: visible;
    }
    #root >div {
      transform: scale(1.3);
      transform-origin: top left;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
    #root >div >div {
      width: 100%;
      height: 100%;
    }
}

.alert-enter {
  opacity: 0;
}
.alert-enter .layout {
  opacity: 0;
  transform: scale(0.5);
}
.alert-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.alert-enter-active .layout {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 200ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit .layout {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}
.alert-exit-active .layout {
  opacity: 0;
  transform: scale(0.5);
  transition: opacity 300ms, transform 200ms;
}

.markers-enter {
  opacity: 0;
  transform: scale(0.9);
}
.markers-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.markers-exit {
  opacity: 1;
}
.markers-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.social-width {
  width: 0;
  overflow: hidden;
  transition: all 300ms;
}

.social-enter-done.social-width {
  width: 65px;
}

.social-enter {
  opacity: 0;
}
.social-enter-active {
  opacity: 1;
}
.social-exit {
  opacity: 1;
}
.social-exit-active.social-width {
  width: 0;
}
.social-exit-active {
  opacity: 0;
}

::-webkit-scrollbar {
  display: none;
}

@keyframes slide {
  0% {
    top: 6.5px;
  }
  20% {
    top: 25px;
  }
  100% {
    top: 6.5px;
  }
}

@keyframes slideMobile {
  0% {
    top: 6.5px;
  }
  20% {
    top: 15px;
  }
  100% {
    top: 6.5px;
  }
}

.twitter {max-width:46px; max-height:45px; }
.twitter::after {content: '\00a0';display: inline-block; width:46px; height:45px; background-position: 1.25% 2.0833333333333335%;background-size: 273.9130434782609% 206.66666666666666%;background-image: url(./images/social-icons.png);padding: 0; }
div.twitter::after {max-width:46px; width:100%;height:0;padding: 0 0 97.82608695652173% 0;}
.twitter-black {max-width:38px; max-height:38px; }
.twitter-black::after {content: '\00a0';display: inline-block; width:38px; height:38px; background-position: 54.54545454545455% 1.8181818181818181%;background-size: 331.57894736842104% 244.73684210526315%;background-image: url(./images/social-icons.png);padding: 0; }
div.twitter-black::after {max-width:38px; width:100%;height:0;padding: 0 0 100% 0;}
.twitter-white {max-width:38px; max-height:38px; }
.twitter-white::after {content: '\00a0';display: inline-block; width:38px; height:38px; background-position: 54.54545454545455% 72.72727272727273%;background-size: 331.57894736842104% 244.73684210526315%;background-image: url(./images/social-icons.png);padding: 0; }
div.twitter-white::after {max-width:38px; width:100%;height:0;padding: 0 0 100% 0;}
.youtube {max-width:46px; max-height:45px; }
.youtube::after {content: '\00a0';display: inline-block; width:46px; height:45px; background-position: 1.25% 97.91666666666667%;background-size: 273.9130434782609% 206.66666666666666%;background-image: url(./images/social-icons.png);padding: 0; }
div.youtube::after {max-width:46px; width:100%;height:0;padding: 0 0 97.82608695652173% 0;}
.youtube-black {max-width:38px; max-height:38px; }
.youtube-black::after {content: '\00a0';display: inline-block; width:38px; height:38px; background-position: 98.86363636363636% 1.8181818181818181%;background-size: 331.57894736842104% 244.73684210526315%;background-image: url(./images/social-icons.png);padding: 0; }
div.youtube-black::after {max-width:38px; width:100%;height:0;padding: 0 0 100% 0;}
.youtube-white {max-width:38px; max-height:38px; }
.youtube-white::after {content: '\00a0';display: inline-block; width:38px; height:38px; background-position: 98.86363636363636% 72.72727272727273%;background-size: 331.57894736842104% 244.73684210526315%;background-image: url(./images/social-icons.png);padding: 0; }
div.youtube-white::after {max-width:38px; width:100%;height:0;padding: 0 0 100% 0;}